@import "@angular/cdk/overlay-prebuilt.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
.button1 {
  @apply bg-theme-gradient-orange text-theme-mediumBlue1 rounded-full py-2 px-6 font-semibold transition-all shadow-button hover:bg-theme-gradient-orange-hover;
}

.button2 {
  @apply bg-theme-gradient-dark-blue-1 text-theme-white/75 rounded-full border-theme-mediumBlue3 border-[1.5px] py-2 px-6 font-semibold transition-all shadow-button hover:shadow-button-hover;
}

.button3 {
  @apply bg-theme-gradient-medium-blue text-theme-white rounded-full py-2 px-6 font-semibold transition-all shadow-button hover:shadow-button-hover;
}

.button4 {
  @apply bg-theme-gradient-red text-theme-mediumBlue1 rounded-full py-2 px-6 font-semibold transition-all shadow-button;
}

.button1:disabled, .button2:disabled, .button3:disabled, .button4:disabled {
  @apply opacity-70 cursor-not-allowed;
}

.modal-custom {
  display: block;
  border-radius: 1rem;
  max-width: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
  padding: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL1VzZXJzL2FuZHJlcy9Eb2N1bWVudHMvc3VwZXJsaWtlcnMvbWljcm9zaXRlcy9NUEcvbXBnL3NyYy9zdHlsZXMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBUTtBQUVSO0FBQ0E7QUFDQTtBQUVBO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiQGFuZ3VsYXIvY2RrL292ZXJsYXktcHJlYnVpbHQuY3NzXCI7XG5cbkB0YWlsd2luZCBiYXNlO1xuQHRhaWx3aW5kIGNvbXBvbmVudHM7XG5AdGFpbHdpbmQgdXRpbGl0aWVzO1xuXG4uYnV0dG9uMSB7XG4gIEBhcHBseSBiZy10aGVtZS1ncmFkaWVudC1vcmFuZ2UgdGV4dC10aGVtZS1tZWRpdW1CbHVlMSByb3VuZGVkLWZ1bGwgcHktMiBweC02IGZvbnQtc2VtaWJvbGQgdHJhbnNpdGlvbi1hbGwgc2hhZG93LWJ1dHRvbiBob3ZlcjpiZy10aGVtZS1ncmFkaWVudC1vcmFuZ2UtaG92ZXJcbn1cblxuLmJ1dHRvbjIge1xuICBAYXBwbHkgYmctdGhlbWUtZ3JhZGllbnQtZGFyay1ibHVlLTEgdGV4dC10aGVtZS13aGl0ZS83NSByb3VuZGVkLWZ1bGwgYm9yZGVyLXRoZW1lLW1lZGl1bUJsdWUzIGJvcmRlci1bMS41cHhdIHB5LTIgcHgtNiBmb250LXNlbWlib2xkIHRyYW5zaXRpb24tYWxsIHNoYWRvdy1idXR0b24gaG92ZXI6c2hhZG93LWJ1dHRvbi1ob3ZlclxufVxuXG4uYnV0dG9uMyB7XG4gIEBhcHBseSBiZy10aGVtZS1ncmFkaWVudC1tZWRpdW0tYmx1ZSB0ZXh0LXRoZW1lLXdoaXRlIHJvdW5kZWQtZnVsbCBweS0yIHB4LTYgZm9udC1zZW1pYm9sZCB0cmFuc2l0aW9uLWFsbCBzaGFkb3ctYnV0dG9uIGhvdmVyOnNoYWRvdy1idXR0b24taG92ZXJcbn1cblxuLmJ1dHRvbjQge1xuICBAYXBwbHkgYmctdGhlbWUtZ3JhZGllbnQtcmVkIHRleHQtdGhlbWUtbWVkaXVtQmx1ZTEgcm91bmRlZC1mdWxsIHB5LTIgcHgtNiBmb250LXNlbWlib2xkIHRyYW5zaXRpb24tYWxsIHNoYWRvdy1idXR0b25cbn1cblxuLmJ1dHRvbjE6ZGlzYWJsZWQsIC5idXR0b24yOmRpc2FibGVkLCAuYnV0dG9uMzpkaXNhYmxlZCwgLmJ1dHRvbjQ6ZGlzYWJsZWQge1xuICBAYXBwbHkgb3BhY2l0eS03MCBjdXJzb3Itbm90LWFsbG93ZWRcbn1cblxuLm1vZGFsLWN1c3RvbSB7XG4gIGRpc3BsYXk6IGJsb2NrO1xuICBib3JkZXItcmFkaXVzOiAxcmVtO1xuICBtYXgtd2lkdGg6IDYwMHB4O1xuICBvdmVyZmxvdy14OiBoaWRkZW47XG4gIG92ZXJmbG93LXk6IHNjcm9sbDtcbiAgYmFja2dyb3VuZDogI2ZmZjtcbiAgcGFkZGluZzogMXJlbTtcbn0iXX0= */