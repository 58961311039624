@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.button1 {
  @apply bg-theme-gradient-orange text-theme-mediumBlue1 rounded-full py-2 px-6 font-semibold transition-all shadow-button hover:bg-theme-gradient-orange-hover
}

.button2 {
  @apply bg-theme-gradient-dark-blue-1 text-theme-white/75 rounded-full border-theme-mediumBlue3 border-[1.5px] py-2 px-6 font-semibold transition-all shadow-button hover:shadow-button-hover
}

.button3 {
  @apply bg-theme-gradient-medium-blue text-theme-white rounded-full py-2 px-6 font-semibold transition-all shadow-button hover:shadow-button-hover
}

.button4 {
  @apply bg-theme-gradient-red text-theme-mediumBlue1 rounded-full py-2 px-6 font-semibold transition-all shadow-button
}

.button1:disabled, .button2:disabled, .button3:disabled, .button4:disabled {
  @apply opacity-70 cursor-not-allowed
}

.modal-custom {
  display: block;
  border-radius: 1rem;
  max-width: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
  padding: 1rem;
}